import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  getDocs,
  query,
  where,
  collection,
  writeBatch,
  serverTimestamp,
  addDoc,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./Firebase.js";
import "./Home.css";
import { stringify } from "@firebase/util";
import FullScreenImage from "./Customhooks/FullScreenImage.js";
import FourFiles from "./Customhooks/FourFiles.js";
import FourFilesUploader from "./Customhooks/FourFilesUploader.js";
import FullScreenPromotion from "./FullScreenPromotion.js";
import FullScreenVideo from "./Customhooks/FullScreenVideo.js";
import MiniVideo from "./Customhooks/FullScreenProps/MiniVideo.js";
import { Fullscreenbackground } from "./Styling/styles";

function Home() {
  const [following, setFollowing] = useState([]);
  const [realfollowings, setRealfollowings] = useState([]);
  const [tempfiles, setTempfiles] = useState([]);
  const [error, setError] = useState("");
  const [twominutevideo, setTwominutevideo] = useState(0);
  const [fullscreentarget, setFullscreentarget] = useState([]);
  const [miniplayer, setMiniplayer] = useState([]);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        console.log(userId);
        const followRef = collection(db, "users", userId, "Following");
        const querySnapshot = getDocs(followRef).then((querySnapshot) => {
          let posts = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          console.log(posts.length);
          console.log(posts[0].Follow_id);
          let num = -1;
          let followkeep = [];
          for (let step = 0; step < posts.length; step++) {
            num++;
            followkeep.push(posts[num].Follow_id);
          }
          console.log(followkeep);

          let followposts = [];
          let num1 = -1;
          for (let step = 0; step < followkeep.length; step++) {
            num1++;
            const displayRef = collection(
              db,
              "users",
              followkeep[num1],
              "Drops"
            );
            const querySnapshot = getDocs(displayRef).then((querySnapshot) => {
              followposts.push(
                querySnapshot.docs.map((doc) => ({
                  ...doc.data(),
                  id: doc.id,
                }))
              );
              setRealfollowings(followposts.flat());
              console.log(realfollowings);
            });
          }
        });
      }
    });
  }, []);

  const sendit = async () => {
    let posts;
    let new_posts;
    const userCollectionRef = collection(db, "users");
    await getDocs(userCollectionRef).then((querySnapshot) => {
      posts = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
    });
    console.log(posts);
    await new Promise((resolve) => {
      /*let*/ new_posts = posts.filter(
        (post) =>
          post.id !== "jJ8UZgDVEzU734mwX4GPskYvXzA3" &&
          post.id !== "1MNrwvjBwEbB7NmJR05bLLTNueq1" &&
          post.id !== "b3wZEloxcxRwAZ9fsYYzhjRaQBF3" &&
          post.id !== "2mJrdxNuRKbqVjAnxeb0cHTrrZR2" &&
          post.id !== "nMjRHgEF6ldn2cHoMxbpJx2k0Df2" &&
          post.id !== "67Ts6SSTpcZyHiu7YzxWmhDQGET2" &&
          post.id !== "BOhuxP952PTB48Er58AnNFWV0rp1" &&
          post.id !== "RKOselx1VsUvvx2u6UxtyvB1kdy1" &&
          post.id !== "nwiA8YNllRNei0CiBrepxwF5N443" &&
          post.id !== "RTQyHbxrnyPfdnVmWOsVQkJR9uI2" &&
          post.id !== "jjZZxnuk1Nc0lYmBWqHzRQM9sTv2" &&
          post.id !== "WLp3v9lyz9T0CC7ixyNcloOXLzV2" &&
          post.id !== "qdvaliFKl1aZyMzU8E2MSMEcEZi1" &&
          post.id !== "xhBTimLSOebBHkaugCg8pYoXex63" &&
          post.id !== "XujOBo8Wp6ewaxHcGybfcnj9oo22" &&
          post.id !== "jQDLCTZyYVWaGVXe9L5FBceuxKl2" &&
          post.id !== "skEtq9tQivV6qIxYoULFqGMAdjk1" &&
          post.id !== "p3wVtRTaBZXi6JQ5llZnfOM7Nhn2" &&
          post.id !== "NKJcporduURY9BIwIsPFo2Qpb5W2" &&
          post.id !== "PEMvmIStwRebBYY5lfliQgZkV062" &&
          post.id !== "JGbmZAIZmrfRpGE6v2EIy0F5cyj2" &&
          post.id !== "BZ4VrkU7SrQk02vgDVCEXVhwhVD3" &&
          post.id !== "V5ZiV6R1eOfvc1cmXCb3pxaMGXl1" &&
          post.id !== "CkqPoESBPFNgqEndJbXvLGaexSD2" &&
          post.id !== "Rs4MxZ6XuJYG3AXI4dhBlFRrwRW2" &&
          post.id !== "kgeYVbZcE4QsUbcMce4GyGpuhVk2" &&
          post.id !== "csLdLwL4WkQpsiIbrXPpRJpbrSr2" &&
          post.id !== "kgeYVbZcE4QsUbcMce4GyGpuhVk2" &&
          post.id !== "bcG8d2dmtFMjaFKcoKUMzscMYYJ2" &&
          post.id !== "WLZ01HbTfhe3M7SmALRHAjZpBqq2" &&
          post.id !== "V6SZs6Lo16awApZ21csqPWyDof03" &&
          post.id !== "U043mO0CAzSWOBbIKOtfCK84f0l2" &&
          post.id !== "mR1z1bgWE5SpEgAQn6KzKL5i6mC2" &&
          post.id !== "RxsmYKVtp1WEl6j0P5TdM5ulf9k2" &&
          post.id !== "hCJ7PGRXdyaECj9H87FKQ4JNA8p2" &&
          post.id !== "mwpxioxmk4fKnALLAiAlO6e5CTF3" &&
          post.id !== "9p2SRuJ9oFUHEZUu5NiQh2r4Q683" &&
          post.id !== "YPWw2ijZ7haOlE5F0bdd8dtlZXf2" &&
          post.id !== "FPKUV8lx4GOJE3oSh2ZDsxGASMT2" &&
          post.id !== "waErcsXQCVfrhuDS1xoutrEFN8v2" &&
          post.id !== "TjcZmKasYrSD1jif4VtOdIvgeDR2" &&
          post.id !== "Imc7amXzdPafjItWl2Ztzq6U7ay1" &&
          post.id !== "fQy057eBAaOFR1KTtz0YzGgjKTh1" &&
          post.id !== "V25htMrDC3h7X9LzE2u9ytPfGtt1" &&
          post.id !== "d00E0UurQRM8GxldHsvY2ry4siv2" &&
          post.id !== "Zbfd8gdJOIdV6cY4GMF1XQKjs6a2" &&
          post.id !== "u5yQav7Bf5UNorVxoKv6JZKzU6i1" &&
          post.id !== "3Z1ul3lUg5b9q3DIBPxJvoAM2X22" &&
          post.id !== "a7IXPvpeYOO2tYH2kGS1hrb9NYm1" &&
          post.id !== "tyVcVtukMuSqw7fC7qyszaBNwV83" &&
          post.id !== "VvpcdKEaFbUlsWo9762hHeEcX4C3" &&
          post.id !== "RGAueNWT37agGWhTDvWfXlvmnPE3" &&
          post.id !== "21Vb5t4RpJPAQS1O6rjJpU1xrMP2" &&
          post.id !== "l0NLc8pyr2XqrQEHyRMJttit4R52" &&
          post.id !== "NkDFoKgVP5TC0LKZaRwm6yaSYt73" &&
          post.id !== "AobPdtP1OUY7A5QUgxAsd8vRajC2" &&
          post.id !== "TsWHOskVCQSyAYlPHP0d18rdTs33" &&
          post.id !== "3IoRNM99bJcNglwotEnXfmLlAQp1" &&
          post.id !== "3qUZiqcUx1RCWPVZdVBkHgjotlp2" &&
          post.id !== "a73FSHL4YwWjxPh2yq9AouQLgWT2" &&
          post.id !== "cckYv63upCaZHCDOJm032n9rQGN2" &&
          post.id !== "gv9s8D5j8OdTqKMwXdZgKxRCEMR2" &&
          post.id !== "HeDDmus4hshpupf1jK1BIXkOktm1" &&
          post.id !== "GNJQLu7L2TNUhZ32KJ7qizW9ujq1" &&
          post.id !== "wrd5qei1QpaPnQ1xqO0fZHiJt5r1" &&
          post.id !== "L3Hm4eOG7uau71jLyuQURI18kCg1" &&
          post.id !== "P51xCtJBbZXKD3rr4hgfxWJZjF13" &&
          post.id !== "wzS1qwaccQeM8qsCDvcTHKzYFw63"
        //always change
      );
      //10 まで
      console.log(new_posts);
      resolve();
    });
    //let messageinfo;
    //let messageinfo1;
    let setref;
    let updateref;
    let addref;
    let addrefid;
    let finaladdref;
    let batchArray = [];
    let batchCount = 0;
    let batchIndex = 0;
    let messageinfo = {
      text: "はじめまして🫶", //always change
      Timestamp: serverTimestamp(),
      image1: "",
      video: "",
      from: "wzS1qwaccQeM8qsCDvcTHKzYFw63", //always change
      attached: false,
    };
    batchArray.push(writeBatch(db));

    setTimeout(() => {
      //maybe for of instead of foreach if we have issues
      new_posts.forEach(async (post) => {
        messageinfo = { ...messageinfo, to: post.id };
        setref = doc(
          db,
          `users/${post.id}/Message/wzS1qwaccQeM8qsCDvcTHKzYFw63` //always change
        );
        await new Promise((resolve) => {
          batchArray[batchIndex].set(setref, {
            ...messageinfo,
            MessageName: "わさび", //always change
            MessageRank: 800, //always change
            MessagePic:
              "https://firebasestorage.googleapis.com/v0/b/eplise.appspot.com/o/images%2FAAkwAAA1739205360014.png?alt=media&token=d5016b18-844e-428b-a087-292d65ed8864", //always change
            Opened: false,
          });

          resolve();
        });

        //.then(() => {

        addref = collection(
          db,
          "users",
          post.id,
          "Message",
          "wzS1qwaccQeM8qsCDvcTHKzYFw63", //always change
          "Message_details"
        );
        addrefid = doc(addref).id;
        finaladdref = doc(
          db,
          "users",
          post.id,
          "Message",
          "wzS1qwaccQeM8qsCDvcTHKzYFw63", //always change
          "Message_details",
          addrefid
        );

        batchArray[batchIndex].set(finaladdref, messageinfo);

        updateref = doc(db, `users/${post.id}/`);

        batchArray[batchIndex].update(updateref, {
          MessageNotification: increment(1),
        });
      });
      batchCount++;
      batchCount++;
      batchCount++;
      if (batchCount === 499) {
        batchArray.push(writeBatch(db));
        batchIndex++;
        batchCount = 0;
      }
    }, 5000);

    setTimeout(() => {
      batchArray.forEach(async (batch) => await batch.commit());
    }, 6000);
    //});
  };

  const finalfilesset = (finaltempfiles) => {
    setTempfiles((prev) => [...prev, ...finaltempfiles]);
  };

  const twominutesubtract = (currentminutes) => {
    setTwominutevideo((prev) => prev - currentminutes);
  };

  const Closefullscreen = (e) => {
    setFullscreentarget([]);
  };

  const Miniplayerset = (shape, videoref, file) => {
    let plugin =
      // shape[1] === "/Arrow1.svg"
      Object.keys(shape).length === 0
        ? // [
          // {},
          {
            width: 70,
            //height: 100,
          }
        : // ]
          // [
          /*  {
              /*    width: 100,
              height: 60,
              background: "linear-gradient(#17d6e7, rgb(230, 98, 194))",
              
            }*/
          {
            width: 100,
            height: 60,
            background: "linear-gradient(#17d6e7, rgb(230, 98, 194))",
          };
    //  ];

    //const passarray = [{ ...plugin, borderRadius: 10 }, { ...videoref }, file];
    //setMiniplayer([...passarray]);

    setMiniplayer(
      [{ ...plugin, borderRadius: 10 }, videoref, file]

      // shape[1] === "/Arrow1.svg"
      /*Object.keys(shape).length === 0
        ? [
            {},
            {
              width: 60,
              height: 100,
            },
            videoref,
            file,
          ]
        : [
            {
              width: 100,
              height: 60,
              background: "linear-gradient(#17d6e7, rgb(230, 98, 194))",
            },
            {
              width: 80,
              height: 100,
            },
            videoref,
            file,
          ]
       */
    );

    setFullscreentarget([]);
  };

  const fullscreenminiplayer = (prop2, prop0, videoref) => {
    console.log("THIS ISSSSSSSSSSSSSSSSSSS");
    setFullscreentarget([
      prop2,
      "video",
      // miniplayerprops[0].width === 70
      !prop0?.background
        ? [{}, "/Arrow1.svg", 37, 700, {}, 702, videoref.muted] //"Long"
        : [
            Fullscreenbackground,
            "/Xout.svg",
            30,
            45,
            { marginTop: "70%", height: 270 },
            47,
            videoref.muted,
          ], //"Wide",
      //maybe this arrays should be imported to both here and fourfiles

      videoref,
    ]);
    setMiniplayer([]);
  };

  return (
    <div
      style={{
        //position: fullscreentarget.length > 0 && "fixed",
        width: fullscreentarget.length > 0 && "100%",
        overscrollBehavior: fullscreentarget.length > 0 && "none",
        touchAction: fullscreentarget.length > 0 && "none",
        overflow: "hidden",

        //the width can be 100% regardless?
      }}
    >
      {/*<button onClick={sendit}>YOOOOOOOOOO</button>*/}
      <img src="/Arrow1.svg" onClick={null} />
      <img src="./Planet1.svg" className="Planet" />
      <img src="./DropView.svg" className="dropb" />

      {fullscreentarget.length > 0 && (
        <div
          style={{
            position: "fixed",

            width: "100%",
            height: "100%",
            //overflowX: "clip",
            zIndex: 2,
          }}
        >
          {fullscreentarget[1] === "image" ? (
            <FullScreenImage
              file={fullscreentarget[0]}
              // type={fullscreentarget[1]}
              close={Closefullscreen}
              shape={fullscreentarget[2]}
            />
          ) : (
            fullscreentarget[1] === "video" && (
              <FullScreenVideo
                file={fullscreentarget[0]}
                // type={fullscreentarget[1]}
                close={Closefullscreen}
                shape={fullscreentarget[2]}
                originalvideoref={fullscreentarget[3]}
                // isit={fullscreentarget[4]}
                setMiniplayer={setMiniplayer}
                Miniplayerset={Miniplayerset}
              />
            )
          )}
        </div>
      )}

      <FourFiles
        tempfiles={tempfiles}
        setTempfiles={setTempfiles}
        //twominutesubtract={twominutesubtract}
        setTwominutevideo={
          setTwominutevideo
          //this setTwominutevideo can be a callback
        }
        //setError={setError}
        fullscreentarget={fullscreentarget}
        setFullscreentarget={setFullscreentarget}
        position={{ position: "absolute", top: "34%" }}
      />

      {/*error*/}
      {
        <FourFilesUploader
          tempfiles={tempfiles}
          finalfilesset={finalfilesset}
          // setTempfiles={setTempfiles}
          setError={setError}
          twominutevideo={twominutevideo}
          setTwominutevideo={
            setTwominutevideo
            //this setTwominutevideo can be a callback
          }
        />
      }
      {miniplayer.length > 0 && (
        <MiniVideo
          miniplayerprops={miniplayer}
          setMiniplayer={setMiniplayer}
          setFullscreentarget={setFullscreentarget}
          fullscreenminiplayer={fullscreenminiplayer}
        />
      )}

      {<FullScreenPromotion />}
      {/*realfollowings.map((realfollowing) => (
        <div className="drops" key={realfollowing.id}>
          <img
            className={
              realfollowing.Profile_image === "/Profile3.svg"
                ? "current3"
                : "current1"
            }
            src={realfollowing.Profile_image}
          />
          {realfollowing.Rank}
        </div>
      ))*/}
    </div>
  );
}

export default Home;
