import React, { useState, useRef } from "react";
import "./FourFiles.css";
import useClickCounter from "../useClickCounter.js";
import Videoduration from "./Videoduration.js";
import { Fullscreenstyles } from "../Styling/styles.js";
import { Fullscreenbackground } from "../Styling/styles";

function FourFiles({
  tempfiles,
  fullscreentarget,
  setFullscreentarget,
  setTwominutevideo,
  // twominutesubtract,
  setTempfiles,
  position,
}) {
  //prolly dont even need a ref? since fullscreening only needs to send it to the compoent instead of using javascript fullscreen method
  const ref3 = useRef([]);
  //prolly dont even need this? just plug in ref

  const [showtime, setShowtime] = useState(false);

  let Fullscreenstyleson = fullscreentarget?.length ? Fullscreenstyles : {};

  const Fullscreencancel = () => {
    //setFullscreened(false);
    //setControlon(false);
    // document.exitFullscreen();
    // setShowtime(true);
  };

  const filestyle = (tempfileid) => {
    let tempstyle =
      tempfiles.length < 2
        ? { width: 200, height: 200 }
        : {
            width: 175,
            height: 175,
          };
    let tempstyle1 =
      tempfiles.length > 2 && tempfileid === tempfiles[tempfiles.length - 1].id
        ? {
            marginRight: 30,
          }
        : {};

    let file = {
      ...tempstyle,
      ...tempstyle1,
      /*marginRight:
        tempfiles.length > 2 &&
        tempfileid === tempfiles[tempfiles.length - 1].id &&
        30 */
    };
    //console.log(file);

    //return tempstyle;
    return file;
  };

  const xoutstyle = (tempfile) => {
    //you can use this if you want but whatever

    let tempstyle =
      tempfiles.length === 1
        ? { left: 170, bottom: 198 }
        : tempfiles.length > 1 && tempfile.includes("video")
        ? { left: 90, bottom: 170 } //if not using props
        : //{ left: 150, bottom: 185 }
          { left: 149, bottom: 171 };

    /*let style={
       ...tempstyle,
     //bottom:tempfiles.length > 1 && 173,
    }

    return style*/

    return tempstyle;

    //test from top to bottom
  };

  const filedelete = (e) => {
    // console.log(twominutevideo);
    if (e.target.id === tempfiles[0].id) {
      if (tempfiles.length === 1) {
        setTempfiles([]);
      } else {
        setTempfiles([...tempfiles.slice(1)]);
      }
      URL.revokeObjectURL(tempfiles[0].file);
      if (tempfiles[0].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) => prevvalue - Math.floor(ref3.current[0].duration)
        );
        //twominutesubtract(Math.floor(ref3.current[0].duration));
      }
    } else {
      let deletetarget = tempfiles.findIndex(
        (element) => e.target.id === element.id
      );
      setTimeout(() => {
        setTempfiles([
          ...tempfiles.slice(0, deletetarget),
          ...tempfiles.slice(deletetarget + 1),
        ]);
      });
      URL.revokeObjectURL(tempfiles[deletetarget].file);
      if (tempfiles[deletetarget].realfile.type.includes("video")) {
        setTwominutevideo(
          (prevvalue) =>
            prevvalue - Math.floor(ref3.current[deletetarget].duration)
        );
        // twominutesubtract(Math.floor(ref3.current[deletetarget].duration));
      }
    }
  };

  return (
    <div
      style={{
        ...Fullscreenstyleson,
        justifyContent: tempfiles.length < 3 && "center",
        marginLeft: tempfiles.length > 2 && 15,
        ...position,
        position: "fixed",
        //this position fixed may have to change eventually if there is a scroll
      }}
      className="filecontainer"
    >
      {/*
        <div
          style={{
            display: "flex",
            overflowX: "auto",
            // overflow: "hidden",
            width: "100%",
            // overflowY: "hidden",
            //position: "relative",
            //this position relative is IMPORTANT to help stop child position absolute from
            //breaking overflow rules of this page
            //  height: 300,
            zIndex: 7,
            ...position,
          }}
        ></div>
     */}
      {/*
            <div
              style={{
                width: "100%",
                overflowX: "auto",
                zIndex: 7,
                ...position,
                // top: "40%",
                display: "flex",
              }}
            ></div>
         */}
      {tempfiles.map((tempfile, index) => (
        <div
          // ref={(element) => (ref3.current[index] = element)}
          //the ref needs to be added directly at the bottom with video

          //change ref3 name to videoref?
          key={tempfile.id}

          //instead of fullscreening it here, through props send both functions to close full screen, and enter full screen
          //thus fullscreen will happen at the parent component

          //videotimeupdate not needed?
          //make sure fullscreen time update and time adjust is good
          //maybe if control on after few seconds put the control off
          //test from top to bottom to see if everything workks
          //maybe instead of setshow and rendering whole component make separate compoenet for the time
          //when it updates chnage the ref and the props to the state will change and rerender the child compeoent
        >
          {tempfile.realfile.type.includes("image") && (
            <img
              onClick={() =>
                setFullscreentarget([tempfile.file, "image", "square"])
              }
              className="tempfile"
              style={{
                /*marginRight:
                  /*(tempfiles.length === 2 &&
                    tempfile.id === tempfiles[tempfiles.length - 1].id &&
                    21) ||
                  tempfiles.length > 2 &&
                  tempfile.id === tempfiles[tempfiles.length - 1].id &&
                  30,*/
                ...filestyle(tempfile.id),
              }}
              src={tempfile.file}
            />
          )}
          {tempfile.realfile.type.includes("video") && (
            <>
              <video
                ref={(element) => (ref3.current[index] = element)}
                //what is the bottom arow abot?
                //onclick full screen or control on?

                controls={tempfiles.length < 2 && true}
                /* width={
                    fullscreened ? "100%" : tempfiles.length === 1 ? 200 : 175
                  }
                  height={
                    fullscreened ? "40%" : tempfiles.length === 1 ? 200 : 175
                  }*/
                playsInline
                autoPlay
                muted
                loop
                className="tempfile"
                id={tempfile.id}
                ////////////////////////////////////////
                //this is needed for BOTH full screen and regular time update
                //if in full screen this might need to
                //also rerender the non full screen time with fake states update
                //accomplish the full screen here??
                //videotime state can be replaced with ref?
                onTimeUpdate={() =>
                  /*tempfiles.length > 1 ? Videotimeupdate : undefined*/
                  tempfiles.length > 1
                    ? setShowtime(!showtime) //maybe a callback function to send to the videoduration props to only rerender THAT compoente each second
                    : undefined
                }
                //dont show the time until nan is gone go fix it should we do this in the parent or the child compoenet
                // onLoadedData={() => setShowtime(true)}// we might not even need this, just get time ontimeupdate working and that would be good enough
                //how to only rerender child component look up
                onClick={() =>
                  tempfiles.length > 1
                    ? setFullscreentarget([
                        tempfile.file,
                        "video",
                        tempfile.realfile.type.includes("quicktime")
                          ? [{}, "/Arrow1.svg", 37, 700, {}, 702] //"Long"
                          : [
                              Fullscreenbackground,
                              "/Xout.svg",
                              30,
                              45,
                              { marginTop: "70%", height: 270 },
                              47,
                            ], //"Wide",

                        ref3.current[index],

                        /* <div style={{ fontSize: 80, color: "red" }}>
                          hey it works!!!!
                        </div>,*/
                      ])
                    : undefined
                }
                //get rid of these fullscreened states?

                style={{
                  ...filestyle(tempfile.id),
                  // ...filestyle(),
                  objectFit: "cover",
                }}
              >
                <source src={tempfile.file} type="video/mp4" />
              </video>

              {tempfiles.length > 1 &&
                tempfile.realfile.type.includes("video") &&
                !isNaN(
                  ref3.current[
                    index /*tempfiles.length - 1 or ref3.current.length - 1*/
                  ]?.duration
                ) /*||
                    tempfiles[tempfiles.length - 1].realfile.type.includes(
                      "image"
                    )*/ && (
                  <span className="videoduration1">
                    {<Videoduration videoref={ref3.current[index]} />}
                    {/* {0 + Math.floor(ref3.current[index]?.currentTime / 60)}:
                      {ref3.current[index]?.currentTime < 10 && 0}
                      {ref3.current[index]?.currentTime >= 60
                        ? Math.floor(ref3.current[index]?.currentTime % 60)
                        : Math.floor(ref3.current[index]?.currentTime)}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>/</span>
                      {0 + Math.floor(ref3.current[index]?.duration / 60)}:
                      {ref3.current[index]?.duration < 10 && 0}
                      {ref3.current[index]?.duration >= 60
                        ? Math.floor(ref3.current[index]?.duration % 60)
                        : Math.floor(ref3.current[index]?.duration)}
                        */}
                  </span>
                )}
            </>
          )}

          {
            //if we have a break time is there way to get useeffect to run before the listerners
            //////////////////////////
            //make twominutevideo callback function
            //if it goes over twominute does it throw error style it
            //when full sceen videos make sure to specify youtube type vodeos, vs tiktok type videos where its 横長 or 縦長
            //refer to the notes
            //fullscreen videos
            //closefull screen
            //all video full screen control
            //control should not be on when tempfile === 1
            //control should not even be in this compoenet
            //actually for all videos make video compoenent so it doesnt rerender images and other shiz that dont need to be rerendered
            //might need to forward ref since you need the ref of the video in the parent component
            //same goes for full screened videos
            //only when fullscreened
            //does all the error show at the bottom?
            //finish the rest of the slash slash beore moving downwards
            //ready the dropping of this files
            //ready the comments ofthis actual files
            //ready this component for comment post as well
            //when we apply this to connnect if we cancel out of drop, is the files gone? hen coming back
            // be able to comment any where (refactor the reusuable comments as well )
            //apply this to otification comments aswell
            //video and images swiple sides (your fils), up,(someone elses) and down
            //able to display posts when truring cntrol on
            //refactor EVERYTHING thats possible!!
            //refactor comments, all posts, display, alldisplay and simdisplays,displyback,
            //deleting
            //refactor observtion on video auto play
            //message video can be full screeened without cutsom compoenent ?
            //////////////////////////////////////////////
          }

          {(tempfile.realfile.type.includes("image") ||
            !isNaN(ref3.current[index]?.duration) ||
            tempfiles.length === 1) && (
            <img
              style={{
                width: 25,
                position: "relative",
                ...xoutstyle(tempfile.realfile.type),
                /* left:
                    (tempfiles.length === 1 && -35) ||
                    (tempfiles.length > 1 &&
                      tempfile.realfile.type.includes("video") &&
                      95) ||
                    150,
                  //(tempfiles.length > 1 && 150),

                  bottom:
                    (tempfiles.length === 1 && 170) ||
                    (tempfiles.length > 1 && 173),*/
              }}
              src="/Xout.svg"
              onClick={filedelete}
              id={tempfile.id}
            />
          )}
        </div>
      ))}
    </div>
  );
}
export default FourFiles;
