import React, { useRef } from "react";
import { Fullscreenbackground } from "../../Styling/styles";

const MiniVideo = ({
  miniplayerprops,
  setMiniplayer,
  setFullscreentarget,
  fullscreenminiplayer,
}) => {
  const videoref = useRef(null);

  /* const videotimeset = () => {
    /* if (
      videoref.current?.src === miniplayerprops[2]
      /*videoref.current?.currentTime
    )
    videoref.current.currentTime = miniplayerprops[1].currentTime;
    console.log("HHHHHHHHHHHHHHHHH");
  };
  if (videoref.current?.src === miniplayerprops[2]) videotimeset();
*/

  return (
    <div
      style={{
        position: "fixed",
        bottom: 60,
        zIndex: 2,
        // ...miniplayerprops[0],
      }}
    >
      <video
        src={miniplayerprops[2]}
        type="video/mp4"
        ref={videoref}
        style={{
          ...miniplayerprops[0],
        }}
        playsInline
        autoPlay={!miniplayerprops[1].paused ? true : false}
        loop
        muted={miniplayerprops[1].muted ? true : false}
        /*onTimeUpdate={() => {
            setShowtime(!showtime);
          }}*/
        onClick={() => {
          fullscreenminiplayer(
            miniplayerprops[2],
            miniplayerprops[0],
            videoref.current
          );
          /*setFullscreentarget([
            miniplayerprops[2],
            "video",
            // miniplayerprops[0].width === 70
            !miniplayerprops[0]?.background
              ? [{}, "/Arrow1.svg", 37, 700, {}, 702] //"Long"
              : [
                  Fullscreenbackground,
                  "/Xout.svg",
                  30,
                  45,
                  { marginTop: "70%", height: 270 },
                  47,
                ], //"Wide",
            //maybe this arrays should be imported to both here and fourfiles

            videoref.current,
          ]);
          setMiniplayer([]);*/
        }}
        onLoadedMetadata={() => {
          videoref.current.currentTime = miniplayerprops[1].currentTime;
          console.log(miniplayerprops[1].muted);
          console.log(videoref.current.muted);
          //change playbackrate here onloaded
          //change muted not transfering to fullscreen when onclicking
        }}
      />
    </div>
  );
};

export default MiniVideo;
