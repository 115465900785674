import React, { useEffect, useState } from "react";

const SpeedSheet = ({ playbacktime, setspeed, canceltimersettings }) => {
  const [movestart, setMovestart] = useState(0);
  const [moveto, setMoveto] = useState(0);

  const timenumbers = ["0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2"];

  const arrowelement = document.createElement("span");
  arrowelement.textContent = "✓";
  arrowelement.style.cssText = "color:skyblue;"; //margin-left:-42px

  useEffect(() => {
    let element = document.getElementById(playbacktime);
    console.log(element);

    element.prepend(arrowelement);
    element.style.display = "flex";
    element.style.gap = "30px";
    element.style.marginLeft = "29.5px";
  }, []);

  return (
    <div
      onClick={(e) => setspeed(e.target.id)}
      onTouchStart={(e) => setMovestart(e.touches[0].pageY)}
      onTouchMove={(e) =>
        movestart < e.touches[0].pageY
          ? setMoveto(movestart - e.touches[0].pageY)
          : undefined
      }
      onTouchEnd={() =>
        55 + moveto <= -10 ? canceltimersettings() : setMoveto(0)
      }
      //when more than 3 files, styling is off
      //move on to videoplayspeed slash slash
      style={{
        width: "100%",
        height: "40%",
        position: "absolute",
        bottom: 55 + moveto, //make this the relatable number to the bottom shiz
        fontSize: 28,
        zIndex: 100,
        background: "linear-gradient(rgb(58, 31, 206), #d9eaf1)",
        borderRadius: 25,
        color: "white",
      }}
    >
      {timenumbers.map((timenumber) => {
        return (
          <div
            style={{ marginBottom: 10, marginLeft: 80 }}
            id={timenumber}
            key={timenumber}
          >
            {timenumber + "x"}
          </div>
        );
      })}
      {/*
      <div style={{ marginBottom: 10 }} id="0.25">
        0.25x
      </div>
      <div style={{ marginBottom: 10 }} id="0.5">
        0.5x
      </div>
      <div style={{ marginBottom: 10 }} id="0.75">
        0.75x
      </div>
      <div style={{ marginBottom: 10 }} id="1">
        1x
      </div>
      <div style={{ marginBottom: 10 }} id="1.25">
        1.25x
      </div>
      <div style={{ marginBottom: 10 }} id="1.5">
        1.5x
      </div>
      <div style={{ marginBottom: 10 }} id="1.75">
        1.75x
      </div>
      <div style={{ marginBottom: 5 }} id="2">
        2x
      </div>
*/}
      {console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR")}
    </div>
  );
};
export default SpeedSheet;
