import React, { useEffect, useState, useRef } from "react";
import "./PopUp.css";
import { auth, db } from "./Firebase.js";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  EmailAuthProvider,
  linkWithCredential,
  onAuthStateChanged,
  deleteUser,
  signOut,
} from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import ErrorModal from "./Customhooks/ErrorModal.js";

function PopUp() {
  const ref1 = useRef(null);

  const [registerPhone, setRegisterPhone] = useState("+81");
  const [pressed, setPressed] = useState(true);
  const [registerCode, setRegisterCode] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [code, setCode] = useState(true);
  const [registerPractice, setRegisterPractice] = useState("");
  const [disablepress, setDisablepress] = useState(false);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        const element = ref1.current;
        //console.log(element);
        element.scrollIntoView({ behavior: "smooth" });
        //setScroll(false);
      }, 1000);
    }
  }, [loaded]);

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };
  ////////////////iphone mission

  const requestOTP = (e) => {
    e.preventDefault();
    setDisablepress(true);
    //console.log("YO");
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, registerPhone, appVerifier)
      .then((confirmationResult) => {
        //console.log(confirmationResult);
        window.confirmationResult = confirmationResult;

        setPressed(!pressed);
        setTimeout(() => {
          setDisablepress(false);
        });
      })
      .catch((error) => {
        // alert("電話番号が正しくありません");
        setError("WrongNumber");
        setTimeout(() => {
          //setRegisterPhone("+81");
          window.location.reload(false);
          //setDisablepress(false);
        }, 2000);
      });
  };

  const SendOTP = (e) => {
    e.preventDefault();
    if (!disablepress) {
      setDisablepress(true);
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(registerCode)
        .then((usercred) => {
          if (usercred.user.email) {
            signOut(auth);
            //alert("この電話番号は既に使われています");
            setError("PhoneNumberInUse");
            setTimeout(() => {
              window.location.reload(false);
            }, 1500);
          } else {
            //alert("本人確認ができました！");
            setCode(false);
            setTimeout(() => {
              setDisablepress(false);
              setError("");
            });
          }
        })
        .catch((error) => {
          //alert("コードが違います");
          setError("WrongCode");
          setDisablepress(false);

          //console.log(error);
        });
    }
  };

  const attach = (e) => {
    //maybe its erroring cause you press it twicce
    //test it
    //if so disable button

    e.preventDefault();
    if (userName.length > 25 || userName.length == 0) {
      //alert("ユーザー名は1-25文字でなければいけません.");
      setError("UserName");
    } else if (registerPractice != registerPassword) {
      //alert("パスワードが一致していません.");
      setError("PasswordNotMatched");
    } else if (
      userName.length < 25 &&
      userName.length > 0 &&
      registerPractice == registerPassword
    ) {
      setDisablepress(true);
      const credential = EmailAuthProvider.credential(
        registerEmail,
        registerPassword
      );
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          //console.log(usercred.user.uid);
          //console.log(auth.currentUser);
          // alert("ユーザー情報を登録しています...そのままお待ち下さい");

          try {
            const userCollectionRef = collection(db, "users");
            getDocs(userCollectionRef).then((querySnapshot) => {
              let Rank = querySnapshot.size + 1;
              setDoc(doc(db, "users", usercred.user.uid), {
                User_Name: userName,
                Total_Currency_Generated: 0,
                Current_Currency: 0,
                Rank: Rank,
                Profile_image: "/Profile3.svg",
                Account: "Public",
                Message_From_All: "Yes",
                Message_Only_From_Pledge: "No",
                Message_Only_From_Followers: "No",
                Message_Rank_Restrictions: 0,
                Comments_From_All: "Yes",
                Comments_Only_From_Pledge: "No",
                Comments_Only_From_Followers: "No",
                Comments_Rank_Restrictions: 0,
                Location: "",
                Notification: 0,
                Deduction: 20,
                MessageNotification: 0,
                PreviousRank: Rank,
              });

              setTimeout(() => {
                //navigate(`/User/${usercred.user.uid}`);
                navigate(`/Connect`, {
                  state: "SignedUp",
                });
              }, 1000);
            });
          } catch (error) {
            //console.log(error);
          }
        })
        .catch((error) => {
          //console.log("Account Linking error", error);
          setDisablepress(false);
          setError("WrongInput");
          //alert("入力情報に過ちがあります");
        });
    }
  };

  return (
    <div className="Pop">
      {
        <img
          onLoad={() => setLoaded(true)}
          style={{
            //marginTop: 20,
            borderRadius: 20,
            //width: "100%",
          }}
          src="./Signuppromotion1.svg"
        />
      }
      {loaded && (
        <>
          <div
            //style={{ marginTop: 21, color: "rgb(54, 174, 217)" }}
            className="ewelcome"
          >
            エプリスへようこそ
          </div>
          {/*<img className="dots" src="./dots.svg" />*/}
          <img className="Profile1" src="./Profile3.svg" />

          {code ? (
            <>
              {
                <img
                  style={{
                    marginTop: 10,
                    width: 28,
                  }}
                  src="./Signuparrow8.svg"
                />
              }
              <img className="phone" src="./Phone3.svg" />

              <form onSubmit={requestOTP}>
                {pressed ? (
                  <>
                    <input
                      className="Send"
                      name="tel"
                      type="tel"
                      placeholder="電話番号"
                      value={registerPhone}
                      onChange={(e) => setRegisterPhone(e.target.value)}
                    />
                    <button
                      disabled={disablepress}
                      ref={ref1}
                      className="code"
                      type="submit"
                      id="sign-in-button"
                    >
                      送信
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      //onLoad={() => setDisablepress(false)}
                      className="Send3"
                      name="number"
                      type="number"
                      placeholder="コード"
                      value={registerCode}
                      onChange={(e) => setRegisterCode(e.target.value)}
                    />
                    <button
                      //disabled={disablepress}
                      className="code1"
                      onClick={SendOTP}
                    >
                      送信コード
                    </button>
                  </>
                )}
              </form>
            </>
          ) : (
            <>
              <form className="user_register" onSubmit={attach}>
                <input
                  //onLoad={()=>setDisablepress(false)}
                  className="Send3"
                  placeholder="ユーザー名"
                  name="name"
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                <input
                  className="Send3"
                  placeholder="e-mail"
                  name="email"
                  type="email"
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
                <input
                  className="Send3"
                  placeholder="パスワード"
                  name="password"
                  type="password"
                  value={registerPractice}
                  onChange={(e) => setRegisterPractice(e.target.value)}
                />

                <input
                  className="Send3"
                  placeholder="パスワード確認"
                  name="password"
                  type="password"
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
                <button
                  disabled={disablepress}
                  className="codesend"
                  type="submit"
                >
                  決定
                </button>
              </form>
            </>
          )}
          {error && <ErrorModal errortype={error} />}
        </>
      )}
    </div>
  );
}

export default PopUp;
